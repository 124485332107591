import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Hero } from '../../components/site-only/hero'
import { Filter } from '../../components/filter'
import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  Grid,
  GridItem,
  Heading,
  List,
  ListItem,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  Section,
  SectionContent,
  SectionHeading,
} from '../../components/section'
import {
  HeadingGroup,
  HeadingSubtitle,
  HeadingTitle,
} from '../../components/heading-group'
import { ListIcon } from '../../components/list-icon'
import { Icon } from '../../components/icon'
import { Link } from '../../components/site-only/link'
import { Carousel, CarouselArrow } from '../../components/carousel'
import { Image } from '../../components/site-only/image'
import { useState } from 'react'
import { isNil } from 'lodash'
import { SlideFadeInView } from '../../components/slide-fade-in-view'
import { Wrapper, WrapperFluid } from '../../components/site-only/wrapper'

// @ts-ignore
import HeroImage from '../../images/juggling_hero.jpg'

// @ts-ignore
import MainImage from '../../images/juggling_school_kaito_bg.jpg'

export default function JugglingSchool({
  data: { juggling_school },
}: PageProps<GatsbyTypes.JugglingSchoolQuery>) {
  const [activeIndex, setActiveIndex] = useState(0)

  const section = juggling_school?.section

  const transition = 'all 0.8s ease-in'

  return (
    <>
      {section && (
        <>
          <Hero
            bgImage={`url(${HeroImage})`}
            bgSize={'cover'}
            bgPos={'right 20% top 0%'}
            pt={90}
            h={{
              base: 400,
              md: 450,
            }}
          >
            <Filter
              type={'opacity'}
              value={0.3}
              bg={'black'}
              position={'absolute'}
              top={0}
              left={0}
              w={'full'}
              h={'full'}
            />
            <Container
              position={'relative'}
              textAlign={{
                base: 'center',
                md: 'unset',
              }}
            >
              <SlideFadeInView>
                <Text
                  display={'inline-block'}
                  textStyle={'lg'}
                  borderBottom={4}
                  pb={2}
                  mb={20}
                  zIndex={1}
                >
                  Service
                </Text>
                <Section>
                  <SectionHeading as={HeadingGroup}>
                    <HeadingTitle>{section.heading?.value}</HeadingTitle>
                    <HeadingSubtitle>
                      {section.subtitle?.element?.value}
                    </HeadingSubtitle>
                  </SectionHeading>
                  {section.contents
                    .filter((_, index) => index >= 0 && index < 3)
                    .map(content => (
                      <SectionContent
                        key={content.key}
                        dangerouslySetInnerHTML={{
                          __html: content.value,
                        }}
                      />
                    ))}
                </Section>
              </SlideFadeInView>
            </Container>
          </Hero>
          <Grid
            templateColumns={{
              md: '40vw 1fr',
            }}
            alignItems={'start'}
          >
            <GridItem
              display={{
                base: 'none',
                md: 'unset',
              }}
              h={'full'}
              bgImage={`url(${MainImage})`}
              bgSize={'cover'}
              bgPos={'center top'}
            />
            <GridItem>
              <Wrapper invert>
                <Container>
                  <SlideFadeInView>
                    <Grid
                      templateColumns={{
                        md: '300px 1fr',
                      }}
                      rowGap={20}
                    >
                      {section.contents
                        .filter((_, index) => index === 3)
                        .map(content => (
                          <GridItem
                            key={content.key}
                            as={'p'}
                            textStyle={'md-bold'}
                            textAlign={{
                              base: 'center',
                              md: 'unset',
                            }}
                            color={'tertiary.500'}
                            colSpan={{
                              md: 2,
                            }}
                            dangerouslySetInnerHTML={{
                              __html: content.value,
                            }}
                          />
                        ))}

                      <GridItem
                        as={List}
                        spacing={15}
                        colSpan={{
                          md: 2,
                        }}
                        variant={'unstyled'}
                      >
                        {section.contents
                          .find((_, index) => index === 4)
                          ?.list.map(item => {
                            return (
                              <ListItem key={item.key}>
                                <ListIcon
                                  // @ts-ignore
                                  as={Icon}
                                  kind={'checkbox'}
                                  color={'tertiary.500'}
                                  fontWeight={'bold'}
                                />
                                <Box
                                  as={'span'}
                                  dangerouslySetInnerHTML={{
                                    __html: item.value,
                                  }}
                                />
                              </ListItem>
                            )
                          })}
                      </GridItem>

                      <Center display={{ base: 'none', md: 'flex' }}>
                        <Divider orientation={'vertical'} h={40} />
                      </Center>

                      <Spacer
                        display={{
                          base: 'none',
                          md: 'unset',
                        }}
                      />

                      <GridItem as={'p'} textAlign={'center'}>
                        {section.contents
                          .filter((_, index) => index === 5)
                          .map(content => {
                            const link = content.link

                            return (
                              <Button
                                key={content.key}
                                as={Link}
                                variant={'rounded.tertiary'}
                                size={'md'}
                                {...link?.properties}
                              >
                                <Icon kind={'external-link'} mr={2} />
                                {link?.value}
                              </Button>
                            )
                          })}
                      </GridItem>
                    </Grid>
                  </SlideFadeInView>
                </Container>
              </Wrapper>
            </GridItem>
          </Grid>

          <Wrapper layerStyle={'light'}>
            {section?.sections
              .filter((_, index) => index === 0)
              .map(section => (
                <SlideFadeInView key={section.key}>
                  <VStack as={Container} size={'md'} spacing={15}>
                    <Heading
                      size={'xs'}
                      dangerouslySetInnerHTML={{
                        __html: section.heading?.value ?? '',
                      }}
                    />

                    <Divider
                      as={'div'}
                      w={30}
                      border={4}
                      color={'secondary.500'}
                    />

                    {section.contents.map(content => (
                      <Text
                        key={content.key}
                        dangerouslySetInnerHTML={{
                          __html: content.value,
                        }}
                      />
                    ))}
                  </VStack>
                </SlideFadeInView>
              ))}

            <WrapperFluid>
              <SlideFadeInView>
                <Box
                  as={'section'}
                  h={'40vw'}
                  w={'100vw'}
                  overflowX={'hidden'}
                  my={20}
                >
                  <Carousel
                    variableWidth={true}
                    centerMode={true}
                    speed={1000}
                    slidesToShow={1}
                    prevArrow={<CarouselArrow left={'10vw'} />}
                    nextArrow={<CarouselArrow right={'10vw'} />}
                    beforeChange={(_, next) => {
                      setActiveIndex(next)
                    }}
                  >
                    {section?.sections
                      .filter((_, index) => index === 0)
                      .map(section => {
                        return section?.sections.map((section, index) => {
                          const image = section.contents[0]?.image?.properties

                          return (
                            <Box key={section.key}>
                              <Box
                                transition={transition}
                                w={{
                                  base: '50vw',
                                }}
                                h={{
                                  base: '40vw',
                                }}
                                px={{
                                  base: '2vw',
                                }}
                                py={{
                                  base: index === activeIndex ? 'unset' : '2vw',
                                }}
                              >
                                <Image
                                  {...image!.src}
                                  alt={image!.alt}
                                  transition={transition}
                                  filter={{
                                    base:
                                      index === activeIndex
                                        ? 'unset'
                                        : 'brightness(40%) blur(4px)',
                                  }}
                                  objectFit={'cover'}
                                  w={{
                                    base: 'full',
                                  }}
                                  h={{
                                    base: 'full',
                                  }}
                                />
                              </Box>
                            </Box>
                          )
                        })
                      })}
                  </Carousel>
                </Box>
              </SlideFadeInView>
            </WrapperFluid>

            <SlideFadeInView>
              {section?.sections
                .filter((_, index) => index === 0)
                .map(section => {
                  return section.sections
                    .filter((_, index) => index === activeIndex)
                    .map(section => (
                      <VStack
                        key={section.key}
                        as={Container}
                        size={'md'}
                        spacing={15}
                        h={140}
                      >
                        <Heading
                          size={'xs'}
                          fontWeight={'normal'}
                          textAlign={'center'}
                        >
                          {section.heading?.value}
                        </Heading>

                        {section.contents
                          .filter((_, index) => index > 0)
                          .map(content => (
                            <Text
                              key={content.key}
                              alignSelf={'flex-start'}
                              dangerouslySetInnerHTML={{
                                __html: content.value,
                              }}
                            />
                          ))}
                      </VStack>
                    ))
                })}
            </SlideFadeInView>
          </Wrapper>

          <Wrapper as={'section'}>
            <SlideFadeInView>
              {section?.sections
                .filter((_, index) => index === 1)
                .map(section => (
                  <VStack
                    key={section.key}
                    as={Container}
                    size={'md'}
                    spacing={15}
                  >
                    <Heading
                      size={'xs'}
                      dangerouslySetInnerHTML={{
                        __html: section.heading?.value ?? '',
                      }}
                    />

                    <Divider
                      as={'div'}
                      w={30}
                      border={4}
                      color={'tertiary.500'}
                    />

                    {section.contents
                      .filter((_, index) => index === 0)
                      .map(content => {
                        return (
                          <Text
                            key={content.key}
                            alignSelf={'flex-start'}
                            dangerouslySetInnerHTML={{
                              __html: content.value,
                            }}
                          />
                        )
                      })}

                    {section.contents
                      .filter((_, index) => index === 1)
                      .map(content => {
                        return (
                          <Text
                            key={content.key}
                            color={'tertiary.500'}
                            textStyle={'md-bold'}
                            alignSelf={'flex-start'}
                            dangerouslySetInnerHTML={{
                              __html: content.value,
                            }}
                          />
                        )
                      })}

                    {section.contents
                      .filter((_, index) => index === 2)
                      .map(content => {
                        return (
                          <List
                            key={content.key}
                            alignSelf={'flex-start'}
                            variant={'tertiary'}
                          >
                            {content.list.map(item => (
                              <ListItem key={item.key}>
                                <ListIcon />
                                <Box
                                  dangerouslySetInnerHTML={{
                                    __html: item.value,
                                  }}
                                />
                              </ListItem>
                            ))}
                          </List>
                        )
                      })}

                    <Divider />

                    {section.contents
                      .filter((_, index) => index === 3)
                      .map(content => {
                        return (
                          <List
                            key={content.key}
                            alignSelf={'flex-start'}
                            size={'xs'}
                          >
                            {content.list.map(item => (
                              <ListItem key={item.key}>
                                <ListIcon />
                                <Box
                                  as={'span'}
                                  display={'inline'}
                                  dangerouslySetInnerHTML={{
                                    __html: item.value,
                                  }}
                                />
                              </ListItem>
                            ))}
                          </List>
                        )
                      })}
                  </VStack>
                ))}
            </SlideFadeInView>
          </Wrapper>

          <Wrapper invert layerStyle={'primary'}>
            <SlideFadeInView>
              {section?.sections
                .filter((_, index) => index === 2)
                .map(section => (
                  <Section
                    key={section.key}
                    as={Container}
                    size={'md'}
                    templateColumns={{
                      base: '1fr',
                      md: '400px 1fr',
                    }}
                    templateAreas={{
                      base: `
                          "heading"
                          "divider"
                          "image"
                          "content"
                          "divider2"
                          "section"
                          `,
                      md: `
                        "heading heading"
                        "divider divider"
                        "image spacer"
                        "image content"
                        "image divider2"
                        "image section"
                        `,
                    }}
                    alignItems={'end'}
                    columnGap={25}
                  >
                    <SectionHeading
                      size={'xs'}
                      justifySelf={'center'}
                      gridArea={'heading'}
                      dangerouslySetInnerHTML={{
                        __html: section.heading?.value ?? '',
                      }}
                    />

                    <Divider
                      as={'div'}
                      w={30}
                      border={4}
                      color={'secondary.500'}
                      justifySelf={'center'}
                      gridArea={'divider'}
                    />

                    {section.contents
                      .filter((_, index) => index === 0)
                      .map(content => {
                        const image = content.image?.properties
                        return (
                          <GridItem
                            key={content.key}
                            gridArea={'image'}
                            alignSelf={'center'}
                          >
                            <WrapperFluid mobileOnly>
                              <Image alt={image?.alt} {...image?.src} />
                            </WrapperFluid>
                          </GridItem>
                        )
                      })}

                    <Box
                      display={{
                        base: 'none',
                        md: 'unset',
                      }}
                      gridArea={'spacer'}
                    />

                    {section.contents
                      .filter((_, index) => index === 1)
                      .map(content => {
                        return (
                          <SectionContent
                            key={content.key}
                            gridArea={'content'}
                            textAlign={{
                              base: 'center',
                              md: 'unset',
                            }}
                            dangerouslySetInnerHTML={{
                              __html: content.value,
                            }}
                          />
                        )
                      })}

                    <GridItem gridArea={'divider2'}>
                      <Divider color={'white'} />
                    </GridItem>

                    {section.sections.map(section => {
                      const heading = section.heading?.value
                      const subtitle = section.subtitle?.element?.value
                      const content = section.contents[0]?.value
                      const link = section.contents[1]?.link

                      return (
                        <Section key={section.key}>
                          <SectionHeading
                            as={HeadingGroup}
                            gridGap={6}
                            textAlign={{
                              base: 'center',
                              md: 'unset',
                            }}
                          >
                            <HeadingTitle fontWeight={'bold'}>
                              {heading}
                            </HeadingTitle>
                            <HeadingSubtitle
                              fontSize={'sm'}
                              fontWeight={'normal'}
                            >
                              {subtitle}
                            </HeadingSubtitle>
                          </SectionHeading>
                          <SectionContent
                            dangerouslySetInnerHTML={{
                              __html: content ?? '',
                            }}
                          />
                          <SectionContent textAlign={'center'}>
                            <Button
                              as={Link}
                              variant={'rounded.outline'}
                              size={'md'}
                              {...link?.properties}
                            >
                              {link?.value}
                              <Icon kind={'right-arrow'} ml={4} />
                            </Button>
                          </SectionContent>
                        </Section>
                      )
                    })}
                  </Section>
                ))}
            </SlideFadeInView>
          </Wrapper>

          <Wrapper invert layerStyle={'light'}>
            <SlideFadeInView>
              <Grid gap={8} justifyContent={'center'}>
                {section.contents
                  .filter((_, index) => index > 5)
                  .map((content, index) => {
                    if (isNil(content.link)) {
                      return (
                        <GridItem
                          key={content.key}
                          as={'p'}
                          color={'secondary.500'}
                          fontSize={9}
                          textAlign={'center'}
                        >
                          {content.value}
                        </GridItem>
                      )
                    } else {
                      const link = content.link
                      return (
                        <GridItem
                          key={content.key}
                          as={'p'}
                          color={'secondary.500'}
                        >
                          <Button
                            as={Link}
                            variant={
                              index === 1
                                ? 'rounded.secondary'
                                : 'rounded.tertiary'
                            }
                            size={'md'}
                            {...link.properties}
                          >
                            {link.value}
                          </Button>
                        </GridItem>
                      )
                    }
                  })}
              </Grid>
            </SlideFadeInView>
          </Wrapper>
        </>
      )}
    </>
  )
}

export const query = graphql`
  query JugglingSchool {
    juggling_school: markdownRemark(
      frontmatter: { title: { eq: "Juggling School" } }
    ) {
      childrenMarkdownElement {
        childrenMarkdownElement {
          childrenMarkdownElement {
            childrenMarkdownElement {
              childrenMarkdownElement {
                properties {
                  src {
                    ...ImageFile
                  }
                }
              }
            }
          }
        }
      }
      section: childElement(filter: { type: { eq: "section" } }) {
        tagName
        ...MarkdownHeading
        ...MarkdownSubtitle
        contents: childrenElement(
          filter: {
            type: { in: ["paragraph", "list"] }
            children: { type: { nin: ["strong"] } }
          }
        ) {
          key: id
          tagName
          value
          ...MarkdownLink
          ...MarkdownImage
          list: childrenElement(filter: { type: { eq: "listItem" } }) {
            key: id
            tagName
            value
          }
        }
        sections: childrenElement(filter: { type: { eq: "section" } }) {
          key: id
          ...MarkdownHeading
          ...MarkdownSubtitle
          contents: childrenElement(
            filter: {
              type: { in: ["paragraph", "list"] }
              children: { type: { nin: ["strong"] } }
            }
          ) {
            key: id
            tagName
            value
            ...MarkdownLink
            ...MarkdownImage
            list: childrenElement(filter: { type: { eq: "listItem" } }) {
              key: id
              tagName
              value
            }
          }
          sections: childrenElement(filter: { type: { eq: "section" } }) {
            key: id
            ...MarkdownHeading
            ...MarkdownSubtitle
            contents: childrenElement(
              filter: {
                type: { eq: "paragraph" }
                children: { type: { nin: ["strong"] } }
              }
            ) {
              key: id
              tagName
              value
              ...MarkdownLink
              ...MarkdownImage
            }
          }
        }
      }
    }
  }
`
