import React from 'react'
import { ListIcon as BaseListIcon, IconProps } from '@chakra-ui/react'

export function ListIcon(props: IconProps) {
  return (
    <BaseListIcon {...props}>
      <path
        fill="currentColor"
        d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
      />
    </BaseListIcon>
  )
}
