import React, { forwardRef } from 'react'
import { As, Box, BoxProps, ComponentWithAs } from '@chakra-ui/react'

export interface FilterProps extends BoxProps {
  type?:
    | 'brightness'
    | 'blur'
    | 'contrast'
    | 'drop-shadow'
    | 'grayscale'
    | 'hue-rotate'
    | 'invert'
    | 'opacity'
    | 'saturate'
    | 'sepia'
  initialValue?: number | string
  value?: number | string
  cancel?: 'normal' | 'hover' | 'none'
  to?: string
}

export const Filter: ComponentWithAs<As, FilterProps> = forwardRef(
  (
    {
      type = 'brightness',
      initialValue,
      value = 0.5,
      _hover,
      cancel = 'none',
      ...other
    },
    ref
  ) => {
    const filter = `${type}(${value})`

    const initialFilter =
      typeof initialValue === 'undefined' ? 'none' : `${type}(${initialValue})`

    return (
      <Box
        display={'inline-block'}
        overflow={'hidden'}
        filter={cancel === 'normal' ? initialFilter : filter}
        transition={'all 0.5s ease'}
        _hover={{
          filter: cancel === 'hover' ? initialFilter : filter,
          ..._hover,
        }}
        ref={ref}
        {...other}
      />
    )
  }
) as ComponentWithAs<As, FilterProps>
